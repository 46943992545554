.portfolio__container{

    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
}

.portfolio__item{

    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

}

.portfolio__item:hover{

    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image{

    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3{

    margin: 1.2rem 0 .5rem;
}

.portfolio__item-cta{
    display: flex;
    margin-top: 1rem;
    /* margin-bottom: rem; */
    gap: 1rem;
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {

    .portfolio__container{

       grid-template-columns: 1fr 1fr; /*50% from left 50% from right  */
       gap: 1.2rem;
    }

}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {

    .portfolio__container{

        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
